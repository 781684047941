import AimOutlined from '@ant-design/icons/AimOutlined';
import CarFilled from '@ant-design/icons/CarFilled';
import CarOutlined from '@ant-design/icons/CarOutlined';
import FormatPainterOutlined from '@ant-design/icons/FormatPainterOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { Dropdown, MenuProps, Spin, Tooltip } from 'antd';
import difference from 'lodash/difference';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_MAP_ZOOM_LEVEL_MIN,
  DEFAULT_MAP_ZOOM_LEVEL_MAX,
} from '~/config/defaults';
import mapThemes from '~/config/mapThemes';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import useMapSettings, { mapThemeKeys } from '~/store/useMapSettings';
import theme from '~/theme';
import { AGENT_STATUS, AgentAlertLevel } from '~/types/agent';
import { ALARM_LEVEL } from '~/types/alarm';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import logger from '~/utils/logger';
import mapFitBounds from '~/utils/map/mapFitBounds';

import useDeepCompareEffectForMaps from './hooks/useDeepCompareEffectForMaps';
import useMarkers, { type MarkerType } from './hooks/useMarkers';
import computeAnchorPoint from './utils/computeAnchorPoint';
import computeIcon from './utils/computeIcon';
import getMarkerClusterRender from './utils/getMarkerClusterRender';

const BUTTON_SIZE = 32;
const GUTTER = 8;

const BlurredDiv = styled.div`
  filter: blur(0.25rem);
  opacity: 0.5;
`;

const MapDiv = styled.div<{ $hasAlertBottomBanner: boolean }>`
  flex-grow: 1;
  height: calc(
    100dvh - ${theme.dimensions.navbarHeight}px -
      ${(props) =>
        props.$hasAlertBottomBanner ? `${theme.dimensions.alarmsBottomBannerHeight}px` : '0px'}
  );
`;

const LoadingDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: ${theme.dimensions.navbarHeight}px;
  z-index: ${theme.layers.loadingSpinner};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: progress;

  & > div {
    width: 168px;
    height: 168px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);

    & > span {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const ButtonsDiv = styled.div`
  position: fixed;
  top: calc(${theme.dimensions.navbarHeight}px + ${GUTTER}px);
  right: 8px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${GUTTER}px;
  z-index: ${theme.layers.mapTopRightActions};

  ${theme.medias.lteSmall} {
    z-index: ${theme.layers.base};
  }

  & > span {
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-size: 18px !important;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.14902);
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
  }
`;

const ACCURACY_CIRCLE_ZOOM_THRESHOLD = 13;

const clusterersLayers: {
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
}[] = [
  { status: AGENT_STATUS.inSafeZone, alertLevel: null },
  { status: AGENT_STATUS.inMission, alertLevel: null },
  { status: AGENT_STATUS.connectionLost, alertLevel: null },
  { status: AGENT_STATUS.alert, alertLevel: ALARM_LEVEL.Information },
  { status: AGENT_STATUS.alert, alertLevel: ALARM_LEVEL.Warning },
  { status: AGENT_STATUS.alert, alertLevel: ALARM_LEVEL.Critical },
];

export interface Props {
  isLoading: boolean;
  isBlurred: boolean;
  initialZoom: google.maps.MapOptions['zoom'];
  initialLat: number;
  initialLng: number;
  followingLat: number | undefined;
  followingLng: number | undefined;
  isFocusedOnAgent: boolean;
}

const MainMapElement = memo(
  ({
    isLoading,
    isBlurred,
    initialZoom,
    initialLat,
    initialLng,
    followingLat,
    followingLng,
    isFocusedOnAgent,
  }: Props) => {
    const { isInitialLoading, isLoading: isAgentsContextLoading } = useAgentsContext();
    const { hasAlertBottomBanner } = useAlarmsContext();
    const { companyFeatures } = useCompanyFeatures();
    const markers = useMarkers();
    const markersRef = useRef<
      {
        data: MarkerType;
        markerElement: google.maps.Marker;
        accuracyCircle: google.maps.Circle | undefined;
      }[]
    >([]);
    const markerClusterersRef = useRef<MarkerClusterer[]>([]);
    const infoWindowRef = useRef(new google.maps.InfoWindow());
    const mapDivRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const mapTheme = useMapSettings((state) => state.mapTheme);
    const setMapTheme = useMapSettings((state) => state.setMapTheme);
    const trafficLayerRef = useRef<google.maps.TrafficLayer>(new google.maps.TrafficLayer());
    const showMapTrafficLayer = useMapSettings((state) => state.showMapTrafficLayer);
    const setShowMapTrafficLayer = useMapSettings((state) => state.setShowMapTrafficLayer);
    const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);
    const [currentZoom, setCurrentZoom] = useState<google.maps.MapOptions['zoom']>(initialZoom);

    useEffect(() => {
      const showAccuracyCircles = currentZoom && currentZoom > ACCURACY_CIRCLE_ZOOM_THRESHOLD;

      markersRef.current.forEach(({ accuracyCircle }) => {
        if (map && showAccuracyCircles) {
          accuracyCircle?.setMap(map);
        } else {
          accuracyCircle?.setMap(null);
        }
      });
    }, [map, currentZoom]);

    const isSomethingLoading = isLoading || isInitialLoading || isAgentsContextLoading;

    const pointsToRecenter = useMemo(
      () =>
        (markers || []).map((marker) => ({ lat: marker.position.lat, lng: marker.position.lng })),
      [markers],
    );

    // Initialize
    useEffect(() => {
      if (!mapDivRef.current || map) {
        return;
      }

      const nextMap = new window.google.maps.Map(mapDivRef.current, {
        // A Map's styles property cannot be set when a mapId is present. When a mapId is present, Map styles are controlled via the cloud console.
        // In this case we are using styles so we can switch theme without reloading the map
        // mapId: mapThemes[mapTheme].id,
        mapTypeId: mapThemes[mapTheme].type,
        styles: mapThemes[mapTheme].styles,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
        cameraControl: false,
        rotateControl: true,
        heading: 0, // For the rotateControl to work
        streetViewControl: false,
        zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
        minZoom: DEFAULT_MAP_ZOOM_LEVEL_MIN,
        maxZoom: DEFAULT_MAP_ZOOM_LEVEL_MAX,
        controlSize: 32,
      });

      nextMap.addListener('zoom_changed', () => {
        setCurrentZoom(nextMap.getZoom());
      });

      try {
        markerClusterersRef.current = clusterersLayers.map(
          (layer) =>
            new MarkerClusterer({
              map: nextMap,
              markers: [],
              renderer: {
                render: getMarkerClusterRender({
                  status: layer.status,
                  alertLevel: layer.alertLevel,
                }),
              },
              algorithm: new SuperClusterAlgorithm({ maxZoom: CARRIER_MAP_ZOOM_LEVEL - 1 }),
            }),
        );
      } catch (error) {
        logger.error('MainMapElement: useEffect cluster initialization error');
        // eslint-disable-next-line no-console
        console.error(error);
      }
      if (showMapTrafficLayer && companyFeatures.liveTrafficOnMap) {
        trafficLayerRef.current.setMap(nextMap);
      }
      setMap(nextMap);
      setIsMapInitialized(true);
    }, [map, mapTheme, showMapTrafficLayer, companyFeatures.liveTrafficOnMap]);

    useEffect(() => {
      if (
        isMapInitialized &&
        map &&
        !isSomethingLoading &&
        !isFocusedOnAgent &&
        markers.length > 0
      ) {
        mapFitBounds({
          map,
          points: pointsToRecenter,
          lockZoomLevel: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapInitialized, map, isSomethingLoading, isFocusedOnAgent, markers.length]);

    useDeepCompareEffectForMaps(() => {
      map?.setOptions({
        zoom: currentZoom,
        center: { lat: initialLat, lng: initialLng },
      });
    }, [map]);

    useDeepCompareEffectForMaps(() => {
      if (followingLat && followingLng) {
        map?.setOptions({
          center: { lat: followingLat, lng: followingLng },
        });
      }
    }, [map, followingLat, followingLng]);

    useEffect(
      () => () => {
        try {
          markerClusterersRef.current.forEach((clusterer) => {
            if (clusterer) {
              clusterer?.clearMarkers();
              clusterer?.setMap(null);
            }
          });
          markersRef.current.forEach(({ markerElement, accuracyCircle }) => {
            if (markerElement) {
              google.maps.event.clearInstanceListeners(markerElement);
              markerElement?.setMap(null);
              accuracyCircle?.setMap(null);
            }
          });
          markerClusterersRef.current = [];
          markersRef.current = [];
        } catch (error) {
          logger.error('MainMapElement: useEffect cluster cleanup error');
          // eslint-disable-next-line no-console
          console.error(error);
        }
      },
      [],
    );

    useEffect(() => {
      map?.setMapTypeId(mapThemes[mapTheme].type);
      map?.setOptions({ styles: mapThemes[mapTheme].styles });
    }, [map, mapTheme]);

    useEffect(() => {
      const currentMarkerIds = markersRef.current.map(({ data }) => data.id);
      const newMarkerIds = markers.map(({ id }) => id);

      const addedMarkerIds = difference(newMarkerIds, currentMarkerIds);
      const removedMarkerIds = difference(currentMarkerIds, newMarkerIds);
      const updatedMarkerIds = newMarkerIds.filter((id) => currentMarkerIds.includes(id));

      // Remove markers
      removedMarkerIds.forEach((makerIdToRemove) => {
        const marker = markersRef.current.find(({ data }) => data.id === makerIdToRemove);
        if (marker) {
          const statusIndex = clusterersLayers.findIndex(
            (layer) =>
              layer.status === marker?.data.status && layer.alertLevel === marker?.data.alertLevel,
          );
          markerClusterersRef?.current?.[statusIndex]?.removeMarker(marker?.markerElement);
          google.maps.event.clearInstanceListeners(marker?.markerElement);
          marker?.accuracyCircle?.setMap(null);
          marker?.markerElement?.setMap(null);
        }
        markersRef.current = markersRef.current.filter(({ data }) => data.id !== makerIdToRemove);
      });

      // Add/update markers
      markers.forEach((markerData) => {
        const commonAccuracyCircleOptions: google.maps.CircleOptions = {
          map,
          center: markerData.position,
          radius: markerData.accuracyCircleRadius,
          fillColor: getAgentStatusColor({
            status: markerData.status || AGENT_STATUS.connectionLost,
            isOffline: false,
            alertLevel: markerData.alertLevel || null,
          }),
          fillOpacity: 0.4,
          strokeColor: 'transparent',
          strokeOpacity: 0,
          zIndex: markerData.zIndex,
        };
        const commonMakerOptions: google.maps.MarkerOptions = {
          map,
          draggable: false,
          anchorPoint: computeAnchorPoint(
            markerData.type,
            markerData.status,
            markerData.isHighlighted,
          ),
          icon: computeIcon(markerData),
          label: {
            text: markerData.label,
            color: 'white',
            fontWeight: '600',
          },
          position: markerData.position,
          zIndex: markerData.zIndex,
          opacity: 1,
        };
        if (addedMarkerIds.includes(markerData.id)) {
          // Add markers
          const accuracyCircle = new google.maps.Circle(commonAccuracyCircleOptions);
          const markerElement: google.maps.Marker = new google.maps.Marker(commonMakerOptions);
          if (markerData.onClick) {
            markerElement.addListener('click', () => markerData.onClick?.(markerData.id));
          }
          markerElement.addListener('mouseover', () => {
            infoWindowRef.current.setContent(markerData.tooltip);
            infoWindowRef.current.open({ map, anchor: markerElement, shouldFocus: false });
          });
          markerElement.addListener('mouseout', () => {
            infoWindowRef.current.close();
          });
          const statusIndex = clusterersLayers.findIndex(
            (layer) =>
              layer.status === markerData.status && layer.alertLevel === markerData.alertLevel,
          );
          markerClusterersRef?.current?.[statusIndex]?.addMarker(markerElement);
          markersRef.current.push({ data: markerData, markerElement, accuracyCircle });
        } else if (updatedMarkerIds.includes(markerData.id)) {
          // Update markers
          const marker = markersRef.current.find(({ data }) => data.id === markerData.id);
          if (marker?.markerElement) {
            marker?.accuracyCircle?.setOptions(commonAccuracyCircleOptions);
            marker?.markerElement?.setOptions(commonMakerOptions);
            marker?.markerElement?.addListener('mouseover', () => {
              infoWindowRef.current.setContent(markerData.tooltip);
              infoWindowRef.current.open({
                map,
                anchor: marker?.markerElement,
                shouldFocus: false,
              });
            });
            marker?.markerElement?.addListener('mouseout', () => {
              infoWindowRef.current.close();
            });
            markersRef.current = markersRef.current.map((item) =>
              item.data.id === markerData.id
                ? {
                    data: markerData,
                    markerElement: marker?.markerElement,
                    accuracyCircle: marker?.accuracyCircle,
                  }
                : item,
            );
          }
        }
      });
    }, [map, markers]);

    const handleZoomIn = useCallback(() => {
      if (map && currentZoom) {
        map.setZoom(currentZoom + 1);
      }
    }, [map, currentZoom]);

    const handleZoomOut = useCallback(() => {
      if (map && currentZoom) {
        map.setZoom(currentZoom - 1);
      }
    }, [map, currentZoom]);

    const handleRecenterMap = useCallback(() => {
      mapFitBounds({
        map,
        points: pointsToRecenter,
        lockZoomLevel: true,
      });
    }, [map, pointsToRecenter]);

    const handleToggleTrafficLayer = useCallback(() => {
      if (map && !trafficLayerRef.current.getMap() && companyFeatures.liveTrafficOnMap) {
        trafficLayerRef.current.setMap(map);
        setShowMapTrafficLayer(true);
      } else {
        trafficLayerRef.current.setMap(null);
        setShowMapTrafficLayer(false);
      }
    }, [map, setShowMapTrafficLayer, companyFeatures.liveTrafficOnMap]);

    const mapThemeMenu: MenuProps = useMemo(
      () => ({
        activeKey: mapTheme,
        items: mapThemeKeys.map((themeKey) => ({
          key: themeKey,
          label: i18n.t(`map.styles.${themeKey}`),
          onClick: () => setMapTheme(themeKey),
          disabled: themeKey === mapTheme,
        })),
      }),
      [mapTheme, setMapTheme],
    );

    const CarIcon = useMemo(
      () => (showMapTrafficLayer ? CarFilled : CarOutlined),
      [showMapTrafficLayer],
    );

    const mapAndMaybeControlButtons = (
      <>
        <MapDiv $hasAlertBottomBanner={hasAlertBottomBanner} ref={mapDivRef} data-id="map" />
        {!isBlurred && (
          <ButtonsDiv>
            <Tooltip placement="left" title={i18n.t('map.zoomIn')}>
              <PlusOutlined onClick={handleZoomIn} data-id="zoom-in-map-btn" />
            </Tooltip>
            <Tooltip placement="left" title={i18n.t('map.zoomOut')}>
              <MinusOutlined onClick={handleZoomOut} data-id="zoom-out-map-btn" />
            </Tooltip>
            <Tooltip placement="left" title={i18n.t('map.recenter')}>
              <AimOutlined onClick={handleRecenterMap} data-id="recenter-map-btn" />
            </Tooltip>
            {companyFeatures.liveTrafficOnMap && (
              <Tooltip
                placement="left"
                title={
                  showMapTrafficLayer
                    ? i18n.t('map.liveTrafficHide')
                    : i18n.t('map.liveTrafficShow')
                }
              >
                <CarIcon
                  onClick={handleToggleTrafficLayer}
                  data-id="live-traffic-map-btn"
                  data-active={showMapTrafficLayer}
                />
              </Tooltip>
            )}
            <Tooltip placement="left" title={i18n.t('map.theme')}>
              <Dropdown
                trigger={['click']}
                menu={mapThemeMenu}
                placement="bottomRight"
                arrow
                data-id="select-map-theme-map-btn"
              >
                <FormatPainterOutlined />
              </Dropdown>
            </Tooltip>
          </ButtonsDiv>
        )}
      </>
    );

    return (
      <>
        {isLoading && (
          <LoadingDiv data-id="map-loading">
            <div>
              <span>{i18n.t('common.loading')}</span>
              <Spin size="large" />
            </div>
          </LoadingDiv>
        )}
        {isBlurred ? (
          <BlurredDiv>{mapAndMaybeControlButtons}</BlurredDiv>
        ) : (
          mapAndMaybeControlButtons
        )}
      </>
    );
  },
);

MainMapElement.displayName = 'MainMapElement';

export default MainMapElement;
