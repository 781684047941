import { gql, useLazyQuery, type ApolloError } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import type { Agent } from '~/types/agent';
import logger from '~/utils/logger';

export interface VideoStreamV2QueryVariables {
  carrierId: string;
}

export interface VideoStreamV2QueryData {
  carrier: {
    __typename: 'Carrier_Cognito';
    id: string;
    video_stream_v2: {
      __typename: 'VideoStreamV2';
      playback_url: string | null; // If null we need to refetch
    };
  };
}

export default function useQueryVideoStreamV2({ agent }: { agent: Agent | undefined }): {
  videoStreamV2?: VideoStreamV2QueryData['carrier']['video_stream_v2'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const [fetchVideoStream, { data, error, loading }] = useLazyQuery<
    VideoStreamV2QueryData,
    VideoStreamV2QueryVariables
  >(
    gql`
      query QueryCarrierVideoStream($carrierId: ID!) {
        carrier(id: $carrierId) {
          id
          video_stream_v2 {
            playback_url
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ carrier }) => {
        logger.log('useQueryVideoStreamV2: onCompleted', {
          carrier,
          agentId: agent?.id,
          playback_url: carrier?.video_stream_v2?.playback_url,
        });
      },
      onError: (e) => {
        logger.error('useQueryVideoStreamV2: query error', { error: e, agentId: agent?.id });
      },
    },
  );

  const shouldRefetch =
    agent?.id &&
    !agent?.maybeVideoStreamV2PlaybackUrl &&
    !loading &&
    !data?.carrier?.video_stream_v2?.playback_url;

  useEffect(() => {
    if (shouldRefetch) {
      logger.log('useQueryVideoStreamV2: refetching because no playback url');
      fetchVideoStream({ variables: { carrierId: agent?.id || '' } });
    }
  }, [shouldRefetch, fetchVideoStream, agent?.id]);

  return useMemo(
    () => ({
      videoStreamV2: data?.carrier?.video_stream_v2,
      isLoading: loading,
      error,
    }),
    [data?.carrier?.video_stream_v2, loading, error],
  );
}
